import React, { ReactElement } from 'react';

interface TabProps {
  title: string;
  children: ReactElement[];
}

const Tab = ({ children }: TabProps) => {
  return <>{children}</>;
};

export default Tab;
