export const automation = {
  personal: [
    { slug: 'payment-actions', first: 10, second: 25, third: 70 },
    { slug: 'unlimited-flows', first: true, second: true, third: true },
    {
      slug: 'incoming-transaction-triggers',
      first: true,
      second: true,
      third: true,
    },
    {
      slug: 'account-balance-triggers',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'repeating-flows', first: true, second: true, third: true },
    {
      slug: 'automatic-payments-in-the-background',
      first: false,
      second: true,
      third: true,
    },
    { slug: 'percentage-actions', first: true, second: true, third: true },
    { slug: 'fill-budget-to-action', first: false, second: true, third: true },
    { slug: 'from-whats-left-action', first: false, second: true, third: true },
    {
      slug: 'forward-over-eur-action',
      first: false,
      second: true,
      third: true,
    },
    { slug: 'flow-builder', first: true, second: true, third: true },
  ],
  business: [
    { slug: 'payment-actions', first: 10, second: 50, third: 350 },
    { slug: 'unlimited-flows', first: true, second: true, third: true },
    {
      slug: 'incoming-transaction-triggers',
      first: true,
      second: true,
      third: true,
    },
    {
      slug: 'account-balance-triggers',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'repeating-flows', first: true, second: true, third: true },
    {
      slug: 'automatic-payments-in-the-background',
      first: false,
      second: true,
      third: true,
    },
    { slug: 'percentage-actions', first: false, second: true, third: true },
    { slug: 'fill-budget-to-action', first: false, second: true, third: true },
    { slug: 'from-whats-left-action', first: false, second: true, third: true },
    {
      slug: 'forward-over-eur-action',
      first: true,
      second: true,
      third: true,
    },
    {
      slug: 'set-aside-your-vat-action',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'flow-builder', first: true, second: true, third: true },
  ],
};

export const investing = {
  personal: [
    {
      slug: 'automated-index-investing',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'investment-templates', first: true, second: true, third: true },
  ],
  business: [
    {
      slug: 'automated-index-investing',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'investment-templates', first: true, second: true, third: true },
  ],
};

export const compatibility = {
  personal: [
    {
      slug: 'unlimited-bank-connections',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'personal-accounts', first: true, second: true, third: true },
    // { slug: 'business-accounts', first: false, second: false, third: true },
  ],
  business: [
    {
      slug: 'unlimited-bank-connections',
      first: true,
      second: true,
      third: true,
    },
    { slug: 'personal-accounts', first: true, second: true, third: true },
    { slug: 'business-accounts', first: false, second: true, third: true },
  ],
};

export const education = {
  personal: [],
  business: [
    { slug: 'profit-first-flows', first: false, second: true, third: false },
    {
      slug: 'profit-first-video-course',
      first: false,
      second: true,
      third: false,
    },
    {
      slug: 'profit-first-audiobook',
      first: false,
      second: true,
      third: false,
    },
    { slug: 'profit-first-coach', first: false, second: true, third: false },
  ],
};

const pricingData = {
  automation,
  // TODO: uncomment when Meesman goes live
  // investing,
  compatibility,
  education,
};

export default pricingData;
