import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { getFlowButtonHandler } from '../Dialog';

const Prices = {
  free: '0',
  plus: '1,99',
  business: '6,99',
  pro: '6,99',
  advanced: '29,99',
} as const;

interface PricingCardProps {
  type: 'free' | 'plus' | 'business' | 'pro' | 'advanced';
}

const PricingCard = ({ type }: PricingCardProps) => {
  const { t } = useTranslation('pricing');

  return (
    <div className="pricing-card">
      <h3 className="pricing-card__title">
        {t(`packagesCards.content.${type}.title`)}
      </h3>
      {type === 'business' && (
        <div className="pricing-card__label">
          {t(`packagesCards.content.${type}.label`)}
        </div>
      )}
      <strong className="pricing-card__price">
        €{Prices[type]}
        <span>/m</span>
      </strong>
      <div className="pricing-card__info">
        {t(`packagesCards.content.${type}.info`)}
      </div>
      <p className="pricing-card__description">
        {t(`packagesCards.content.${type}.description`)}
      </p>
      <button
        className="btn btn--primary btn--sm pricing-card__button"
        onClick={() => getFlowButtonHandler()}
      >
        {t(`packagesCards.buttonTitle`)}
      </button>
    </div>
  );
};

export default PricingCard;
