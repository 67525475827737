import React, { ReactElement, useState } from 'react';
import cx from 'classnames';

interface TabsProps {
  children: ReactElement[];
}

const Tabs = ({ children }: TabsProps) => {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div className="tabs">
      <div className="tabs__wrapper">
        <div className="tabs__tablist">
          {children.map((item, index) => (
            <button
              key={index}
              className={cx('tabs__tab', {
                'tabs__tab--active': selectedTab === index,
              })}
              onClick={() => setSelectedTab(index)}
            >
              {item.props.title}
            </button>
          ))}
        </div>
      </div>
      <div className="tabs__content">{children[selectedTab]}</div>
    </div>
  );
};

export default Tabs;
