import React from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import automation from '../../assets/images/icons/automation.svg';
import investing from '../../assets/images/icons/investing.svg';
import compatibility from '../../assets/images/icons/compatibility.svg';
import education from '../../assets/images/icons/education.svg';
import check from '../../assets/images/icons/check.svg';
import timesAlt from '../../assets/images/icons/times-alt.svg';
import pricingData from './pricingData';

interface TableRowProps {
  type: PricingTableProps['type'];
  name: 'automation' | 'investing' | 'compatibility' | 'education';
  data: {
    slug: string;
    first: boolean | number;
    second: boolean | number;
    third: boolean | number;
  };
}

const TableRow = ({ name, data }: TableRowProps) => {
  const { t } = useTranslation('pricing');
  return (
    <tr>
      <td>
        <h4 className="pricing-table__title pricing-table__title--tetriary">
          {t(`${name}Table.content.${data.slug}.title`)}
        </h4>
        <p className="pricing-table__description">
          {t(`${name}Table.content.${data.slug}.description`)}
        </p>
      </td>
      <td>
        {typeof data.first === 'boolean' ? (
          <img
            className="pricing-table__icon"
            src={data.first ? check : timesAlt}
            alt={data.first ? 'check' : 'times'}
          />
        ) : (
          data.first
        )}
      </td>
      <td>
        {typeof data.second === 'boolean' ? (
          <img
            className="pricing-table__icon"
            src={data.second ? check : timesAlt}
            alt={data.second ? 'check' : 'times'}
          />
        ) : (
          data.second
        )}
      </td>
      <td>
        {typeof data.third === 'boolean' ? (
          <img
            className="pricing-table__icon"
            src={data.third ? check : timesAlt}
            alt={data.third ? 'check' : 'times'}
          />
        ) : (
          data.third
        )}
      </td>
    </tr>
  );
};

interface PricingTableProps {
  type: 'personal' | 'business';
}

const PricingTable = ({ type }: PricingTableProps) => {
  const { t } = useTranslation('pricing');
  return (
    <table className="pricing-table">
      <thead>
        <tr>
          <th></th>
          <th>
            <h3 className="pricing-table__title pricing-table__title--secondary">
              {t(`tableLabels.${type}.0`)}
            </h3>
          </th>
          <th>
            <h3 className="pricing-table__title pricing-table__title--secondary">
              {t(`tableLabels.${type}.1`)}
            </h3>
          </th>
          <th>
            <h3 className="pricing-table__title pricing-table__title--secondary">
              {t(`tableLabels.${type}.2`)}
            </h3>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colSpan={4}>
            <div className="pricing-table__inline">
              <img
                className="pricing-table__logo"
                src={automation}
                alt="automation"
              />
              <h2 className="pricing-table__title">
                {t(`automationTable.title`)}
              </h2>
            </div>
          </td>
        </tr>
        {pricingData.automation[type].map((el) => {
          return (
            <TableRow type={type} name="automation" key={el.slug} data={el} />
          );
        })}
        {/*//TODO: uncomment when Meesman goes live
         <tr>
          <td colSpan={4}>
            <div className="pricing-table__inline">
              <img
                className="pricing-table__logo"
                src={investing}
                alt="investing"
              />
              <h2 className="pricing-table__title">
                {t(`investingTable.title`)}
              </h2>
            </div>
          </td>
        </tr>
        {pricingData.investing[type].map((el) => {
          return (
            <TableRow type={type} name="investing" key={el.slug} data={el} />
          );
        })} */}
        <tr>
          <td colSpan={4}>
            <div className="pricing-table__inline">
              <img
                className="pricing-table__logo"
                src={compatibility}
                alt="compatibility"
              />
              <h2 className="pricing-table__title">
                {t(`compatibilityTable.title`)}
              </h2>
            </div>
          </td>
        </tr>
        {pricingData.compatibility[type].map((el) => {
          return (
            <TableRow
              type={type}
              name="compatibility"
              key={el.slug}
              data={el}
            />
          );
        })}
        {type === 'business' && (
          <>
            <tr>
              <td colSpan={4}>
                <div className="pricing-table__inline">
                  <img
                    className="pricing-table__logo"
                    src={education}
                    alt="education"
                  />
                  <h2 className="pricing-table__title">
                    {t(`educationTable.title`)}
                  </h2>
                </div>
              </td>
            </tr>
            {pricingData.education.business.map((el) => {
              return (
                <TableRow
                  type={type}
                  name="education"
                  key={el.slug}
                  data={el}
                />
              );
            })}
          </>
        )}
      </tbody>
    </table>
  );
};

export default PricingTable;
