import React from 'react';
import { graphql, PageProps } from 'gatsby';
import StepsSection from '../components/home/StepsSection';
import Layout from '../components/Layout';
import PricingCard from '../components/pricing/PricingCard';
import PricingTable from '../components/pricing/PricingTable';
import Seo from '../components/SEO/seo';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Tab, Tabs } from '../components/tabs';

const Pricing = (page: PageProps) => {
  const { t } = useTranslation('pricing');
  return (
    <Layout>
      <Seo page={page} title="Flow Pricing" />
      <main className="page-pricing">
        <section className="section-1">
          <div className="container">
            <div className="section-1__content">
              <div className="section-1__block u-spacing-large">
                <h2 className="section-1__title u-title-primary">
                  {t('heroSection.title', 'Compare subscriptions')}
                </h2>
                <div className="section-1__text u-text-secondary u-color-lavender">
                  {t(
                    'heroSection.description',
                    'Simple and honest pricing. Choose a plan or use Flow free'
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <Tabs>
          <Tab title={t('tabTitles.personal')}>
            <section className="section-2">
              <div className="section-2__wrapper">
                <div className="section-2__content">
                  <PricingCard type="free" />
                  <PricingCard type="plus" />
                  <PricingCard type="pro" />
                </div>
              </div>
            </section>
            <section className="section-3">
              <div className="container">
                <div className="section-3__content">
                  <PricingTable type="personal" />
                </div>
              </div>
            </section>
          </Tab>
          <Tab title={t('tabTitles.business')}>
            <section className="section-2">
              <div className="section-2__wrapper">
                <div className="section-2__content">
                  <PricingCard type="free" />
                  <PricingCard type="business" />
                  <PricingCard type="advanced" />
                </div>
              </div>
            </section>
            <section className="section-3">
              <div className="container">
                <div className="section-3__content">
                  <PricingTable type="business" />
                </div>
              </div>
            </section>
          </Tab>
        </Tabs>
        <section className="section-4">
          <div className="container">
            <div className="section-4__content">
              <div className="section-4__card">
                <h3 className="section-4__title">{t('customPricing.title')}</h3>
                <div className="section-4__description">
                  {t('customPricing.description')}
                </div>
                <a className="section-4__link u-link-primary" href="#">
                  {t('customPricing.buttonTitle')}
                </a>
              </div>
            </div>
          </div>
        </section>
        <StepsSection />
      </main>
    </Layout>
  );
};

export default Pricing;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
